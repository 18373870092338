const useAddressValidator = () => {
  const onBlurHandler = (
    e: any,
    fieldName: string,
    form: any,
    setIsAddressFieldValidDestination: any,
    setIsAddressFieldValid: any
  ) => {
    const apiKey = process.env.GOOGLE_PLEACES_KEY;
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      e.target.value
    )}&key=${apiKey}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then(async (data) => {
        if (data?.results?.length > 0) {
          const isAddressInUS = data?.results[0]?.address_components?.filter(
            (component: any) =>
              component?.long_name === "United States" &&
              component?.short_name === "US"
          );
          if (isAddressInUS?.length > 0) {
            fieldName === "origin"
              ? setIsAddressFieldValid(true)
              : setIsAddressFieldValidDestination(true);
          } else {
            fieldName === "origin"
              ? setIsAddressFieldValid(false)
              : setIsAddressFieldValidDestination(false);
            form.setFields([
              {
                name: fieldName === "origin" ? "Origin" : "Destination",
                errors: ["Invalid Address, Only addresses within the US are accepted. Please make sure to enter a valid address within the US."],
              },
            ]);
          }
        } else {
          fieldName === "origin"
            ? setIsAddressFieldValid(false)
            : setIsAddressFieldValidDestination(false);
          form.setFields([
            {
              name: fieldName === "origin" ? "Origin" : "Destination",
              errors: ["Address selected is invalid"],
            },
          ]);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  return {
    onBlurHandler: onBlurHandler,
  };
};

export default useAddressValidator;
