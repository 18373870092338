import styles from "./style.module.scss";
import { FeaturedBannerProps } from "./types";
import CustomButton from "@components/shared/Button";
import { useContext, useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize.js";
import parser from "html-react-parser";
import { AppContext } from "~/src/libs/context";
const FeaturedBanner = ({
  bannerTitle,
  bannerDescription,
  backgroundImage,
  backgroundImageMobile,
  btnText,
  btnUrl = "",
  bannerImg,
  bannerImgMobile,
  textPosition,
  AllowedUsers = "Both",
  promotionID
}: FeaturedBannerProps) => {
  const windowWidth = useWindowResize();
  const [bannerStyle, setBannerStyle] = useState<any>(null);
  const { guestUser } = useContext<any>(AppContext);

  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);

  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  useEffect(() => {
    setBannerStyle(backgroundStyle);
  }, [windowWidth]);

  const backgroundStyle = {
    backgroundImage:
      windowWidth > 576
        ? backgroundImage
          ? textPosition === "left"
            ? `url(${backgroundImage?.url})`
            : `url(${backgroundImage?.url}) , linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`
          : ""
        : backgroundImageMobile
        ? textPosition === "left"
          ? `url(${backgroundImageMobile?.url})`
          : `url(${backgroundImageMobile?.url}) , linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`
        : "",
    justifyContent: textPosition == "left" ? "start" : "end"
  };
  const stringToHTML = function (str: string) {
    const dom = document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };

  return (
    <>
      {isGuestUser && (AllowedUsers === "Guest" || AllowedUsers === "Both") && (
        <div
          className={` ${
            bannerImg && bannerImg.url && styles.bannerWithImage
          } bg-white`}
        >
          <div
            className={`${styles.BannerContainer} ${
              textPosition == "left" ? styles.Left : styles.Right
            } container-fluid text-white`}
            style={bannerStyle}
          >
            <div className={`${styles.BannerContent}`}>
              <h1
                className={`${styles.BannerTitle} font-bolder-xxl lh-sm mb-6`}
              >
                {parser(bannerTitle || "")}
              </h1>
              <h2
                className={`${styles.BannerDescription} font-regular-h5 lh-lg mb-20`}
              >
                {bannerDescription}
              </h2>
              <Link href={btnUrl}>
                <a>
                  <CustomButton
                    text={btnText}
                    theme={"primary-inverse"}
                    className={`${styles.BannerBtn} py-7 px-21`}
                  />
                </a>
              </Link>
            </div>
            {windowWidth > 768
              ? bannerImg &&
                bannerImg?.url && (
                  <div className={styles.BannerImage}>
                    <Image
                      src={bannerImg?.url}
                      alt={bannerImg?.altText}
                      width={780}
                      height={780}
                    />
                  </div>
                )
              : bannerImgMobile &&
                bannerImgMobile?.url && (
                  <div className={styles.BannerImage}>
                    <Image
                      src={bannerImgMobile?.url}
                      alt={bannerImgMobile?.altText}
                      width={342}
                      height={342}
                    />
                  </div>
                )}
          </div>
        </div>
      )}
      {!isGuestUser &&
        (AllowedUsers === "Registered" || AllowedUsers === "Both") && (
          <div
            className={`${
              bannerImg && bannerImg.url && styles.bannerWithImage
            } bg-white`}
          >
            <div
              className={`${styles.BannerContainer} ${
                textPosition == "left" ? styles.Left : styles.Right
              } container-fluid text-white`}
              style={bannerStyle}
            >
              <div className={`${styles.BannerContent}`}>
                <h1
                  className={`${styles.BannerTitle} font-bolder-xxl lh-sm mb-6`}
                >
                  {parser(bannerTitle || "")}
                </h1>
                <h2
                  className={`${styles.BannerDescription} font-regular-h5 lh-lg mb-20`}
                >
                  {bannerDescription}
                </h2>
                <Link href={btnUrl}>
                  <a>
                    <CustomButton
                      text={btnText}
                      theme={"primary-inverse"}
                      className={`${styles.BannerBtn} py-7 px-21`}
                    />
                  </a>
                </Link>
              </div>
              {windowWidth > 768
                ? bannerImg &&
                  bannerImg?.url && (
                    <div className={styles.BannerImage}>
                      <Image
                        src={bannerImg?.url}
                        alt={bannerImg?.altText}
                        width={780}
                        height={780}
                      />
                    </div>
                  )
                : bannerImgMobile &&
                  bannerImgMobile?.url && (
                    <div className={styles.BannerImage}>
                      <Image
                        src={bannerImgMobile?.url}
                        alt={bannerImgMobile?.altText}
                        width={342}
                        height={342}
                      />
                    </div>
                  )}
            </div>
          </div>
        )}
    </>
  );
};

export default FeaturedBanner;
