import Header from "@components/shared/Header";
import HeroBanner from "@components/shared/HeroBanner";
import SimplySellMore from "@components/shared/SimplySellMore";
import Hero from "@components/shared/Hero";
import CategoryBanner from "@components/shared/CategoryBanner";
import Carousel from "@components/shared/CustomCarousel";
import Footer from "@components/shared/Footer";
import BrandPage from "@components/shared/Brand";
import CardSection from "@components/shared/CardsList";
import FeaturedBanner from "@components/shared/FeaturedBanner";
import ContactUs from "./components/shared/ContactUs";
import AboutUs from "./components/shared/AboutUs";
import Testimonials from "./components/shared/Testimonials";
import MixedPalletBanner from "@components/organsim/MixedPallet/Banner";
import OrderConfirmation from "./components/shared/OrderConfirmation";
import RequestProduct from "./components/shared/RequestProduct";
import VerticalSpace from "./components/shared/VerticalSpace";
import SlickBanner from "./components/shared/SlickBanner";
import HaggleBanner from "./components/shared/HaggleBanner";
import InPress from "@components/shared/Press";
import { HelpCenter } from "./components/shared/HelpCenter";
import Privacy from "@components/shared/Privacy";
import OverviewCards from "./components/shared/OverviewCards";
import PromotionHomeBanner from "@components/shared/PromotionHomeBanner";
import JoinOurSection from "@components/shared/JoinOurSection";
import BrandBanner from "@components/shared/BrandBanner";
import AboutUsHeroBanner from "@components/shared/AboutUsHeroBanner";
import BenefitsCards from "@components/shared/BenefitsCards";
import FeaturedMoveBanner from "@components/shared/FeaturedMoveBanner";
import HalfHeroBanner from "./components/shared/HalfHeroBanner";
import RequestQuote from "./components/shared/RequestQuote";
import HeroWithTiles from "./components/shared/HeroWithTiles";
import HaggleTable from "./components/shared/HaggleTable"
export const componentsById: any = {
  HalfHeroBanner,
  RequestQuote,
  HeroWithTiles,
  Header,
  HeroBanner,
  SimplySellMore,
  Hero,
  CardSection,
  FeaturedBanner,
  CategoryBanner,
  Carousel,
  Footer,
  BrandPage,
  ContactUs,
  AboutUs,
  Testimonials,
  HaggleBanner,
  MixedPalletBanner,
  FeaturedMoveBanner,
  InPress,
  HelpCenter,
  Privacy,
  OverviewCards,
  PromotionHomeBanner,
  OrderConfirmation,
  RequestProduct,
  VerticalSpace,
  SlickBanner,
  HaggleTable,
  JoinOurSection,
  BrandBanner,
  AboutUsHeroBanner,
  BenefitsCards,
};
