import React, { useContext } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Link from "next/link";
import { CardProps } from "./types";
import { AppContext } from "@src/libs/context";
import { Row, Col } from "antd";
import { useState, useEffect } from "react";
import Icon from "@components/shared/Icon";
import parser from "html-react-parser";
import { useRouter } from "next/router";
import ContactUsModal from "../HaggleModal/HaggleModal";

const OverviewCards = ({
  cards,
  AllowedUsers,
  cardsBackgroundColor,
  title,
  subTitle,
}: CardProps) => {
  const { guestUser } = useContext<any>(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  useEffect(() => {
    const handleClick = (event: any) => {
      event.preventDefault();
      setOpenModal(true);
    };
    const link = document.getElementById("link");
    link?.addEventListener("click", handleClick);
    return () => {
      link?.removeEventListener("click", handleClick);
      setIsLoaded(false)
    };
  }, [isLoaded]);
  if (isGuestUser && AllowedUsers === "Registered") return <></>;
  if (!isGuestUser && AllowedUsers === "Guest") return <></>;
  const router = useRouter();
  const isMovePage = router?.asPath === "/move" ? true : false;


  return (
    <div
      className={`container ${styles.mainContainer}`}
      style={{
        backgroundColor: cardsBackgroundColor ? cardsBackgroundColor : "white",
      }}
    >
      <div className={styles.moveTitle}>
        {title && (
          <h2
            className={`font-bolder-lg pt-40 pb-18 text-darkGray ${styles.title}`}
          >
            {parser(title || "")}
          </h2>
        )}
        {subTitle && (
          <h5 className={`font-regular-h5 text-darkGray ${styles.subtitle}`}>
            {parser(subTitle || "")}
          </h5>
        )}
      </div>
      <Row
        gutter={16}
        justify="space-between"
        className={classNames(styles.cardsContainer)}
      >
        {cards &&
          cards?.map((item, index) => (
            <Col
              className={isMovePage ? `${styles.cardSection}` : ""}
              span={24}
              lg={isMovePage ? 6 : 8}
              key={index}
            >
              <Link href={isMovePage ? '#' : item?.linkUrl} target="_blank">
                <a
                  className={styles.cardItem}
                  onClick={(event: any) => {
                    if (isMovePage) {
                      event.preventDefault();
                      setOpenModal(true);
                    } else {
                      return;
                    }
                  }}
                >
                  <div className="p-15">
                    <div
                      className={classNames(
                        isMovePage ? "font-bold-h3" : "font-bolder-h2 mb-18",
                        styles.cardTitle,
                      )}
                    >
                      {isMovePage ? (
                        <h3 className={`font-bold-h3 ${isMovePage? styles.moveCardTitle:''}`}>
                          <img
                            height={24}
                            src="https://cdn.bfldr.com/SK3DIHJL/at/vcxkwxt4ns9r3v6zn8mqxt6n/quote-icon.svg?auto=webp&format=png"
                          />
                          <span>{item?.title}</span>
                        </h3>
                      ) : (
                        item?.title
                      )}
                    </div>
                    <p
                      className={classNames(
                        "mb-16",
                        styles.cardDescription,
                        isMovePage? styles.moveCards:''
                      )}
                    >
                      {item?.description}
                    </p>
                    <p
                      className={classNames(
                        styles.cardLink,
                        "mb-4 text-oxfordBlue font-bold-base lh-sm"
                      )}
                    >
                      <span>{item?.linkText}</span>
                    </p>
                  </div>
                  <Icon
                    name="rightArrow"
                    classesName={styles.rightArrow}
                    color="#000000"
                    width={10}
                    height={18}
                  />
                </a>
              </Link>
            </Col>
          ))}
      </Row>
      <ContactUsModal
        title="Contact Us"
        subTitle="Let us share how Move can unlock your logistics"
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
      />
    </div>
  );
};

export default OverviewCards;
