import React from 'react'
import {Row,Col }from "antd"
import style from "./style.module.scss";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";
import {AboutUsHeroBannerProps} from "./types"
import Image from "next/image";
const AboutUsHeroBanner = ({
    image,
    mobileImage,
    largeScreenImage,
    mainTitle,
    subTitle,
    description,
    numberList,
    productTitle,
    productDescription,
    bannerColor,
    productImage
}:AboutUsHeroBannerProps) => {
    const isMobile = useWindowResize() <= 576;
    const isLargeScreen = useWindowResize() > 1600;

    const bannerStyle ={
      backgroundImage: isMobile? `url(${mobileImage?.url})`: isLargeScreen?
      `url(${largeScreenImage?.url})` : `url(${image?.url})` 
    }
  return (
    <div className={style.mainWrapper}>
        <Row className='mb-0'>
         <div className={style.heroContainer}>
          <div className={style.heroImgWrapper} style={bannerStyle}>
            <div className={style.heroContentContainer} >
              <div className={`container ${style.noPaddingContainer}`}>
                <h1 className={`font-medium-h4 text-white mb-12 ${style.mainTitle}`}>
                  {mainTitle}
                </h1>
                <h2 className={`text-white font-bolder-xl lh-sm ${style.subText}`}>
                  <div dangerouslySetInnerHTML={{
                    __html: subTitle
                  }} />
                </h2>
              </div>
            </div>
          </div>
        </div>
        </Row>
        <Row>
             <div className={`bg-primary ${style.bottomBanner} pt-30`} style={{ backgroundColor: bannerColor }}>
                <div className={`container ${style.productWrapper}`}>
                    <Row className={style.productTitleWrapper}>
                        <Col className={`${style.noPaddingContainer}`} span={24} md={12}>
                           <div className={`font-regular-h6 text-white lh-lg ${style.description}`}
                               dangerouslySetInnerHTML={{
                                __html: description
                              }}
                           />
                      
                        </Col>
                        <Col span={24} md={10} >
                            <div className={style.numberWrapper}>
                            {numberList?.map((item,index)=>
                              <div className={style.itemWrapper} key={index}>
                                <div className='font-medium-h1 text-white'>
                                 {item?.number}
                                </div>
                                <div className='font-medium-sm text-white'>
                                 {item?.title}
                                </div>
                            </div>
                            )}
                            </div>
                        </Col>
                    </Row>
                    <Row className={`${style.noPaddingContainer}`}style={{ alignItems: "center" }}>
                    <Col span={24} md={10}>
                      <div className={style.teamImage}>
                        <Image
                            unoptimized
                            src={productImage?.url}
                            alt={productImage?.url}
                            layout="fill"
                            objectFit="cover"
                        />
                        </div>
                      </Col>
                      <Col span={24} md={12}>
                        <div className={`font-bolder-lg mb-15 text-white px-40 ${style.productTitle}`}
                            dangerouslySetInnerHTML={{
                                __html: productTitle
                              }}
                        />
                     
                        <div className={`font-regular-h6 text-white px-40 lh-lg ${style.productDescription}`}
                            dangerouslySetInnerHTML={{
                            __html: productDescription
                            }}
                        />                      
                      </Col>
                    </Row>
                </div>
             </div> 
        </Row>
    </div>
  )
}
export default AboutUsHeroBanner