import { Col, Row, Image } from "antd";
import { HeroProps } from "./types";
import styles from "./styles.module.scss";
import parser from "html-react-parser";
import CustomButton from "../Button";
import { useState } from "react";
import HaggleModal from "../HaggleModal/HaggleModal";

const HaggleBanner = ({
  image,
  backgroundImage,
  heading,
  subHeading,
  description,
  buttonText,
  backgroundColor,
}: HeroProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  return (
    <div
      className={`container ${
        backgroundColor ? styles.heroWithBackgroundColor : ""
      }`}
      style={{
        backgroundImage: backgroundImage?.url
          ? `url(${backgroundImage?.url})`
          : "none",
        backgroundColor: backgroundColor
          ? backgroundColor
          : backgroundImage
          ? "#aa7a4d"
          : "",
      }}
    >
      <HaggleModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />

      <Row className={styles.banner}>
        <Col span={24} lg={12} className={`${styles.banner__info} py-30`}>
          {heading && (
            <h1 className={`font-bold-xl ${styles.banner__title}`}>
              {parser(heading)}
            </h1>
          )}
          {subHeading && (
            <h2 className={`font-bold-h3 ${styles.banner__subTitle}`}>
              {parser(subHeading)}
            </h2>
          )}
          {description && (
            <div
              className={` ${styles.banner__description} font-regular-h5 text-darkGray`}
            >
              {parser(description)}
            </div>
          )}
          {buttonText && (
            <CustomButton
              className="mt-2 font-bold-base p-8 h-auto"
              text={buttonText}
              theme="primary"
              onClick={handleOpenModal}
            />
          )}
        </Col>
        <Col span={24} lg={12} className={styles.banner__haggleImage}>
          <Image
            className={styles.image}
            alt="Haggle"
            src={image?.url}
            preview={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default HaggleBanner;
