import styles from "./styles.module.scss"
import classNames from "classnames"
import Image from "next/image";
import { CardProps } from "./types"
import { Row, Col } from "antd"
const BenefitsCards = ({ cards, cardsBackgroundColor }: CardProps) => {
  return (
    <div
      className="container"
      style={{
        backgroundColor: cardsBackgroundColor
          ? cardsBackgroundColor
          : "#382315",
      }}
    >
      <Row gutter={16} className={classNames(styles.cardsWrapper, "py-23")}>
        {cards &&
          cards?.map((item, index) => (
            <Col span={24} lg={8} key={index}>
              <div className={classNames(styles.cardItem, "px-8 d-flex text-platinum py-9")}>
                <div className="me-10 mt-1 mb-6">
                   <Image
                      src={item?.image?.url}
                      alt={item?.image?.altText}
                      width={60}
                      height={60}
                    />
                </div>
                <div className={styles.cardDetails}>
                  <h3
                    className="font-bolder-h5 mb-5"
                  >
                    {item?.title}
                  </h3>
                  <p
                    className="font-regular-base mb-0"
                  >
                    {item?.description}
                  </p>
                </div>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  )
}

export default BenefitsCards
