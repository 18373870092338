import React, { useContext } from "react";
import { Col, Row } from "antd";
import styles from "./styles.module.scss";
import { HeroBannerProps } from "./types";
import CustomButtons from "@components/shared/Button";
import parser from "html-react-parser";
import { AppContext } from "@src/libs/context";
import Link from "next/dist/client/link";
import { useState, useEffect } from "react";

const HeroBanner = ({ type, items, AllowedUsers }: HeroBannerProps) => {
  const { guestUser } = useContext<any>(AppContext);

  const [isGuestUser, setIsGuestUser] = useState<boolean | undefined>(
    undefined
  );
  const checkIfGuest =
    (isGuestUser && AllowedUsers === "Registered") ||
    (!isGuestUser && AllowedUsers === "Guest");

  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);
  const stringToHTML = function (str: string) {
    const dom = document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };

  return (
    <>
      {typeof isGuestUser !== "undefined" && !checkIfGuest ? (
        <div
          className={`${styles.HeroBannerContainer} bg-white container pt-20`}
        >
          <Row gutter={[16, 16]} className={styles.antRow}>
            {items?.map((item, index) => {
              return (
                <Col
                  key={index}
                  className={`gutter-row ${styles[item.imagePosition]} ${
                    styles.antCol
                  } ${
                    type === "Three cards" ? `${styles.threeCards} pb-30` : ""
                  }`}
                  xs={{ span: 24 }}
                  sm={{ span: index === 0 && type === "Hero" ? 16 : 8 }}
                >
                  <div
                    className={
                      item.imagePosition === "center"
                        ? type === "Three cards"
                          ? styles.reverseCenterItemWrapper
                          : styles.centerItemWrapper
                        : styles.leftItemWrapper
                    }
                    style={{
                      backgroundColor: item.backgroundColor,
                    }}
                  >
                    <img
                      className={
                        item.imagePosition === "top-right"
                          ? styles.topRightImage
                          : item.imagePosition === "bottom-right"
                          ? styles.bottomRightImage
                          : type === "Three cards"
                          ? styles.outCenterImage
                          : styles.centerImage
                      }
                      src={item.image?.url}
                      alt={item.image.altText}
                    />
                    <div
                      className={`${styles.content}  ${
                        styles[item.imagePosition]
                      }`}
                    >
                      <h2
                        className={`${styles.mainText}`}
                        style={{ color: item.textColor }}
                      >
                        {parser(item.mainText || "")}
                      </h2>
                      <h3
                        className={styles.subText}
                        style={{ color: item.textColor }}
                      >
                        {item.subText}
                      </h3>
                      {isGuestUser ? (
                        <Link href={`${item.btnUrl}`}>
                          <a>
                            <CustomButtons
                              className={styles.customButton}
                              theme={
                                item.imagePosition === "top-right"
                                  ? "primary"
                                  : "primary-inverse"
                              }
                            >
                              {item.btnText}
                            </CustomButtons>
                          </a>
                        </Link>
                      ) : (
                        <Link href={`${item.btnUrlLoggedIn}`}>
                          <a>
                            <CustomButtons
                              className={styles.customButton}
                              theme="primary-inverse"
                            >
                              {item.btnTextLoggedIn}
                            </CustomButtons>
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeroBanner;
