import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { HeroProps } from "./types";
import CustomButtons from "@components/shared/Button";
import parser from "html-react-parser";
import { AppContext } from "@src/libs/context";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";
import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import ContactUsModal from "../HaggleModal/HaggleModal";

const Hero = ({
  image,
  mobileImage,
  heading,
  subHeading,
  linkLabel,
  linkLabelMobile,
  linkUrl,
  AllowedUsers,
  promotionID,
}: HeroProps): JSX.Element => {
  const router = useRouter();
  const isMovePage = router?.asPath === "/move" ? true : false;
  const { guestUser } = useContext<any>(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useWindowResize() <= 576;
  const isTablet = useWindowResize() <= 768;
  const [isGuestUser, setIsGuestUser] = useState<boolean | undefined>(
    undefined
  );
  const checkIfGuest =
    (isGuestUser && AllowedUsers === "Registered") ||
    (!isGuestUser && AllowedUsers === "Guest");
  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  return (
    <>
      {typeof checkIfGuest !== "undefined" && !checkIfGuest ? (
        <div
          className={`${
            router?.asPath === "/move" ? styles.moveHeroContainer : ""
          } ${styles.heroContainer}`}
        >
          <div>
            <img
              className={styles.heroImage}
              src={isTablet ? mobileImage?.url : image?.url}
              alt={isTablet ? mobileImage?.altText : image?.altText}
            />
            <div
              className={`${[styles.heroContentContainer].join(" ")} ${
                router?.asPath === "/move"
                  ? styles.moveHeroContentContainer
                  : ""
              }`}
            >
              <div className="container">
                <h2
                  className={[
                    styles.heading,
                    isMobile ? "font-bolder-lg" : "font-black-xxxl",
                  ].join(" ")}
                >
                  {parser(heading || "")}
                </h2>
                <h3
                  className={[
                    "mb-10 font-regular-",
                    isMobile ? "base" : "h5",
                  ].join("")}
                >
                  {parser(subHeading || "")}
                </h3>

                <Link href={isMovePage ? "" : linkUrl}>
                  <a
                    onClick={(event: any) => {
                      if (isMovePage) {
                        event.preventDefault();
                        setOpenModal(true);
                      } else {
                        return;
                      }
                    }}
                  >
                    <CustomButtons className={styles.linkBtn} theme="primary">
                      {isMobile ? linkLabelMobile || linkLabel : linkLabel}
                    </CustomButtons>
                  </a>
                </Link>
              </div>
              <ContactUsModal
                title="Contact Us"
                subTitle="Let us share how Move can unlock your logistics"
                isModalOpen={openModal}
                setIsModalOpen={setOpenModal}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Hero;
