import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Style from "./styles.module.scss";
import SearchInput from "@components/shared/Fields";
import Icon from "@components/shared/Icon";
import SearchIcon from "@assets/icons/search.svg";
import { Row, Col, Divider, Tabs } from "antd";
import Accordion from "@components/shared/Accordion";
import Link from "next/link";
import ContactUs from "../ContactUs";
import { HelpCenterProps, faqsProps } from "./types";
import Image from "next/image";
import Button from "@components/shared/Button";
import HelpCenterPage from "./HelpCenterPage";
import { useRouter } from "next/router";
import { AppContext } from "~/src/libs/context";
import { getText } from "@src/libs/resources";
export const HelpCenter = ({
  bannertitle,
  bannerImage,
  buyer,
  vendor,
  bannerTitleColor,
  thanksMessage,
  linkText,
  linkValue,
  thanksImage,
  faqs,
}: HelpCenterProps) => {
  const router = useRouter();
  const { setHelpCenterQuery } = useContext<any>(AppContext);
  const [isHelpCenterPage, setIsHelpCenterPage] = useState(false);
  const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${bannerImage?.url})`,
  };
  const [inputValue, setInputValue] = useState("");
  const [item, setCurrentItem] = useState();
  const [category, setCategory] = useState();
  const [isFormSent, setIsFormSentHelpCenter] = useState(false);
  const [isSearchOpen, setisSearchOpen] = useState(false);
  const [filteredFaqs, setFilteredFaqs] = useState<faqsProps[]>(faqs);
  const [filteredBuyers, setFilteredBuyers] = useState<faqsProps[]>();
  const [filteredVendors, setFilteredVendors] = useState<faqsProps[]>();

  const filterValues = (values: any, type: string, searchValue: string) => {
    let arr = [];
    arr = values?.filter((item: any) =>
      item?.header
        ?.toLocaleLowerCase()
        ?.includes(searchValue?.toLocaleLowerCase())
    );
    if (type === "buyer")
      arr = arr?.filter((item: any) => item?.bussnissType === "buyer");
    if (type === "vendor")
      arr = arr?.filter((item: any) => item?.bussnissType === "vendor");
    return arr;
  };
  const handelInputChange = (e: any) => {
    setisSearchOpen(true);
    let inputSearchValue = e?.currentTarget?.value;
    setInputValue(inputSearchValue);
    if (!inputSearchValue) {
      setFilteredFaqs(faqs);
      setFilteredBuyers(
        faqs?.filter((item: any) => item?.bussnissType === "buyer")
      );
      setFilteredVendors(
        faqs?.filter((item: any) => item?.bussnissType === "vendor")
      );
    } else {
      setFilteredFaqs(filterValues(faqs, "all", inputSearchValue));
      setFilteredBuyers(filterValues(faqs, "buyer", inputSearchValue));
      setFilteredVendors(filterValues(faqs, "vendor", inputSearchValue));
    }
  };
  const backToHelp = () => {
    setisSearchOpen(false);
    setInputValue("");
  };
  const DetailsPanel = ({ content }: any) => {
    return <div>{content}</div>;
  };
  const FaqsSelected = ({ dataSource }: any) => {
    return (
      <div>
        {dataSource?.map((item: any, index: number) => (
          <Accordion
            key={index}
            className={`${Style.faqsPanel} mb-8 py-4`}
            header={item?.header}
            content={<DetailsPanel content={item?.content} />}
            closedIcon={
              <Icon
                name={"down"}
                width={24}
                height={24}
                dir={""}
                color="#02254a"
              />
            }
            openedIcon={
              <Icon
                name={"down"}
                width={24}
                height={24}
                dir={"top"}
                color="#02254a"
              />
            }
          />
        ))}
      </div>
    );
  };
  const items = [
    {
      label: `All`,
      key: "1",
      children: <FaqsSelected dataSource={filteredFaqs} />,
    },
    {
      label: `Buyer`,
      key: "2",
      children: <FaqsSelected dataSource={filteredBuyers} />,
    },
    {
      label: `Vendor`,
      key: "3",
      children: <FaqsSelected dataSource={filteredVendors} />,
    },
  ];
  const navigationHandler = (label: any, item: any, link: any) => {
    setHelpCenterQuery(link.slice(1));
    setCategory(label);
    setIsHelpCenterPage(true);
    setCurrentItem(item);
  };
  useEffect(() => {
    setIsHelpCenterPage(false);
  }, []);
  useEffect(() => {
    if (Object.keys(router?.query)?.[0] === "slug") {
      setIsHelpCenterPage(false);
    } else {
      setIsHelpCenterPage(true);
    }
  }, [router?.query]);
  return (
    <div className="bg-white">
      {!isHelpCenterPage ? (
        <div className="container py-20">
          {!isFormSent ? (
            <div>
              <div
                className={classNames(Style.banneContainer)}
                style={bannerStyle}
              >
                <div className={`${Style.searchWrapper} mx-6`}>
                  <h1
                    className={classNames(
                      Style.bannerTextWrapper,
                      "font-bolder-xl lh-md  mb-8"
                    )}
                    style={{ color: `${bannerTitleColor}` }}
                    dangerouslySetInnerHTML={{
                      __html: bannertitle,
                    }}
                  />
                  <SearchInput
                    type="search"
                    onChange={(e: any) => handelInputChange(e)}
                    onIconClick={(e: any) => handelInputChange(e)}
                    onPressEnter={(e: any) => handelInputChange(e)}
                    placeholder="Search"
                    aria-label="Search"
                    hasIcon={true}
                    Icon={SearchIcon}
                    allowClear={{
                      clearIcon: <Icon name="mobileCross" classesName="py-5 d-flex" />,
                    }}
                    className={Style.searchField}
                    value={inputValue}
                    prefix={true}
                  />
                </div>
              </div>
              {isSearchOpen ? (
                <div
                  className={classNames(Style.searchResultContainer, "mt-16")}
                >
                  <Button
                    theme="primary"
                    className={classNames(
                      Style.backIconWrapper,
                      "text-oxfordBlue bg-white $oxford-blue font-regular-h6 mb-10"
                    )}
                    icon={<Icon name="arrow" dir="top" />}
                    onClick={backToHelp}
                  >
                    {getText()?.HelpCenter?.backToHelpCenter}
                  </Button>
                  <div className="font-bold-lg lh-lg text-oxfordBlue">
                    {getText()?.HelpCenter?.searchResults}
                  </div>
                  <div className="font-regular-base">
                    {filteredFaqs?.length} {getText()?.HelpCenter?.resultsFor}
                    <span className="font-bold-base ps-1"> “{inputValue}”</span>
                  </div>
                  <Divider type="horizontal" className={Style.divider} />
                  <Tabs
                    defaultActiveKey="1"
                    items={items}
                    tabBarStyle={{
                      border: "none",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <Row
                    className={classNames(Style.linksSectionContainer, "my-20")}
                  >
                    <Col span={24}>
                      <Button
                        theme="primary"
                        className={classNames(
                          Style.backIconWrapper,
                          "bg-white  mb-10"
                        )}
                      >
                        <span className="font-bold-lg text-oxfordBlue ms-6 mb-4">
                          {getText()?.HelpCenter?.buyerTitle}
                        </span>
                      </Button>
                    </Col>
                    {buyer &&
                      buyer?.map((item) => (
                        <Col
                          key={item?.name}
                          span={8}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                        >
                          <Link href={`/help-center/${item?.link}-buyer`}>
                            <a
                              onClick={() =>
                                navigationHandler(
                                  "Buyer",
                                  item?.name,
                                  `${item?.link}-buyer`
                                )
                              }
                            >
                              <div
                                className={classNames(
                                  Style.linkBoxContainer,
                                  "font-bold-h3 text-oxfordBlue"
                                )}
                              >
                                {item?.name}
                              </div>
                            </a>
                          </Link>
                        </Col>
                      ))}
                  </Row>
                  <Row
                    className={classNames(Style.linksSectionContainer, "my-20")}
                  >
                    <Col span={24}>
                      <Button
                        theme="primary"
                        className={classNames(
                          Style.backIconWrapper,
                          "bg-white  mb-10"
                        )}
                      >
                        <span className="font-bold-lg text-oxfordBlue ms-6 mb-4">
                          {getText()?.HelpCenter?.vendorTitle}
                        </span>
                      </Button>
                    </Col>
                    {vendor &&
                      vendor?.map((item) => (
                        <Col
                          key={item?.name}
                          span={24}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                        >
                          <Link href={`/help-center${item?.link}-vendor`}>
                            <a
                              onClick={() =>
                                navigationHandler(
                                  "Vendor",
                                  item?.name,
                                  `${item?.link}-vendor`
                                )
                              }
                            >
                              <div
                                className={classNames(
                                  Style.linkBoxContainer,
                                  "py-10 font-bold-h3 text-oxfordBlue"
                                )}
                              >
                                {item?.name}
                              </div>
                            </a>
                          </Link>
                        </Col>
                      ))}
                  </Row>
                  {faqs && (
                    <div className={Style.questionsContainer}>
                      <div className="font-bold-lg lh-lg text-oxfordBlue mb-10">
                        {getText()?.HelpCenter?.FAQSTitle}
                      </div>
                      {faqs?.map((item, index) => (
                        <Accordion
                          key={index}
                          className={`${Style.faqsPanel} mb-8 py-4`}
                          header={item?.header}
                          content={<DetailsPanel content={item?.content} />}
                          closedIcon={
                            <Icon
                              name={"down"}
                              width={24}
                              height={24}
                              dir={""}
                              color="#02254a"
                            />
                          }
                          openedIcon={
                            <Icon
                              name={"down"}
                              width={24}
                              height={24}
                              dir={"top"}
                              color="#02254a"
                            />
                          }
                        />
                      ))}
                    </div>
                  )}
                  <div
                    className={classNames(
                      Style.contactHeader,
                      "font-bold-lg lh-lg text-oxfordBlue mb-10 mt-14"
                    )}
                  >
                    {getText()?.HelpCenter?.contactUsTitle}
                  </div>
                  <div className={classNames(Style.contactUsContainer)}>
                    <ContactUs
                      setIsFormSentHelpCenter={setIsFormSentHelpCenter}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={classNames(Style.thanksMessageContainer, "my-30")}>
              <div
                className={classNames(
                  "font-regular-h3 text-oxfordBlue mb-4",
                  Style.message
                )}
                dangerouslySetInnerHTML={{
                  __html: thanksMessage || "",
                }}
              />
              <div className={Style.imgWrapper}>
                <Image
                  unoptimized
                  src={thanksImage?.url || ""}
                  alt={thanksImage?.altText}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <a
                href={linkValue}
                className="text-primary font-regular-h6 mt-10"
              >
                {linkText}
              </a>
            </div>
          )}
        </div>
      ) : (
        <HelpCenterPage
          itemLabel={item}
          category={category}
          setCategory={setCategory}
          buyer={buyer}
          vendor={vendor}
          setIsHelpCenterPage={setIsHelpCenterPage}
        />
      )}
    </div>
  );
};
