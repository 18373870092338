import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
import { Row, Col, notification } from "antd";
import { CategoryBannerProps } from "./types";
import classnames from "classnames";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { getText } from "~/src/libs/resources";

const CategoryBanner = ({
  title,
  isStorePage,
  brand,
  subTitle,
  allCategories,
}: CategoryBannerProps) => {
  const [bannerImg, setBannerImage] = useState("");
  const [brandImage, setBrandImage] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [brandDesc, setBrandDesc] = useState<any>("");
  const [hasImage, setHasImage] = useState(false)

  const bannerImageHandler = async () => {
    setBrandImage("");
    setBrandLogo("");
    if (isStorePage) {
      const brandSlug = decodeURIComponent(brand as string)?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")
        ?.replace(/-/g, " ")
        ?.replace(/[^\w ]/g, "")
        ?.replace(/ +/g, " ")
        ?.replace(/\ /g, "-")
        ?.toLowerCase();
      try {
        await axios
          .get(`/api/ERP/common/brands/${brandSlug}`, {
            headers: { sessionId: getCookie("sessionid") },
          })
          .then((res) => {
            if(res?.data?.itg_logo_image){
              setBrandLogo(res?.data?.itg_logo_image)
            }
            else{
              setBrandLogo(
                "https://cdn.bfldr.com/SK3DIHJL/at/8wgstqrhshwrkz2mmj8xgrff/Main.svg?auto=webp&format=png"
              );}
            setBrandDesc(res?.data?.content);
            if(res?.data?.itg_header_image){
              setBrandImage(res?.data?.itg_header_image)
              setHasImage(true)
            } else {
              setHasImage(false)
            }

          });
      } catch (e) {
        notification.error({
          message: getText()?.General?.Messages?.SomethingWrongTryAgain,
          duration: 4,
        });
      }
    } else {
      allCategories?.forEach(async function (item: any) {
        const imgSrc = item?.children?.find(
          (item: any) => item.name === decodeURIComponent(subTitle as string)
        );
        if(imgSrc?.itg_header_image) {
          await setBannerImage(imgSrc?.itg_header_image);
           setHasImage(true)
          } else {
            setHasImage(false)
          }
      });
    }
  };
  useEffect(() => {
    bannerImageHandler();
  }, [bannerImg, title, subTitle]);
  useEffect(() => {
    if (allCategories?.length > 0) {
      bannerImageHandler();
    }
  }, [allCategories]);
  const bannerBackgroundColor = isStorePage
    ? "bg-oxfordBlue"
    : hasImage
    ? styles.bannerContainer
    : "bg-primaryLighter"
  return (
    <div className={bannerBackgroundColor}>
      <div className={"container"}>
        <Row>
          <Col
            span={24}
            lg={hasImage ? { span: 12 } : {span : 24 }}
          >
            <div
              className={classnames(
                styles.leftSection
              )}
            >
              {isStorePage ? (
                <div className="text-white">
                  <Row
                    justify="center"
                    align="middle"
                    className={`${styles.bannerImage}
                    mb-12 bg-white`}
                  >
                    <img src={brandLogo} alt="brand" />
                  </Row>
                  <h1 className="font-bolder-xl mb-8">
                    {getText()?.PLP?.CategoryBanner?.welcomeMessage}
                    <span className={`${styles.brandName} ms-6`}>
                      {decodeURIComponent(brand as string)}
                    </span>
                  </h1>
                  { brandDesc !== "" ? 
                    <div
                      dangerouslySetInnerHTML={{
                        __html: brandDesc
                      }}
                    />
                    :
                    <>
                      <p className="mb-5">
                        {getText()?.PLP?.CategoryBanner?.brandDescription}
                      </p> 
                      <p>
                        {getText()?.PLP?.CategoryBanner?.brandSubDescription}
                      </p> 
                    </>
                  }
                </div>
              ) : (
                <h1 className="font-bolder-xl lh-sm m-0">
                {decodeURIComponent(
                  subTitle
                    ? subTitle
                    : title
                )}
              </h1>
              )}
            </div>
          </Col>
          {hasImage &&
            <Col span={24} md={{ span: 12 }}>
              <div
                className={classnames(styles.imageWrapper, "mx-10 my-12")}
              >
                {isStorePage ? <Image
                  unoptimized
                  src={brandImage}
                  alt={decodeURIComponent(brand as string)}
                  layout="fill"
                  objectFit="contain"
                />:
                <Image
                  unoptimized
                  src={bannerImg}
                  alt={decodeURIComponent(subTitle ? subTitle : title)}
                  layout="fill"
                  objectFit="contain"
                />
                }
              </div>
            </Col>
          }
        </Row>
      </div>
    </div>
  );
};

export default CategoryBanner;
