import React from "react";
import Style from "./style.module.scss";
import { PrivacyProps } from "./types";
import classNames from "classnames";
const Privacy = ({
  privacyTitle,
  privacyItems,
  terms,
  termsTitle
}: PrivacyProps) => {
  return (
    <div className="container">
      <div>
        <div className="font-regular-h3 mb-10 mt-30">{termsTitle}</div>
        {terms?.map((item, index) => (
          <div key={index}>
            <div className="mb-4">{item?.title}</div>
            <div
              className={classNames(
                Style.descriptionWrapper,
                "font-regular-base lh-md"
              )}
              dangerouslySetInnerHTML={{
                __html: item?.text
              }}
            />
          </div>
        ))}
      </div>
      <div className="mb-30">
        <div className="font-regular-h3 mb-10 mt-30">{privacyTitle}</div>
        {privacyItems?.map((item, index) => (
          <div key={index}>
            <div className="mb-4">{item?.title}</div>
            <div
              className={classNames(
                Style.descriptionWrapper,
                "font-regular-base lh-md"
              )}
              dangerouslySetInnerHTML={{
                __html: item?.text
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Privacy;
