import React from "react";
import { HeroWithTilesProps } from "./types";
import parser from "html-react-parser";
import { Carousel } from "antd";
import styles from "./styles.module.scss";
import { useWindowResize } from "~/src/utilities/hooks/useWindowResize";

const HeroWithTiles = ({
  heading,
  tiles,
  tilesSectionBackground,
  backgroundImage,
  backgroundMobileImage,
}: HeroWithTilesProps): JSX.Element => {
  const isMobile = useWindowResize() <= 425;
  const settings = {
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: false,
    centerMode: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundImage: isMobile
            ? backgroundMobileImage
              ? `url(${backgroundMobileImage?.url})`
              : "none"
            : backgroundImage
            ? `url(${backgroundImage?.url})`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="container py-40">
          {heading && (
            <h3 className={`font-bolder-lg text-white py-12 ${styles.heading}`}>
              {parser(heading || "")}
            </h3>
          )}
        </div>
      </div>
      <div
        style={{
          background: tilesSectionBackground ? tilesSectionBackground : "none",
          marginTop: "-1px",
        }}
      >
        <Carousel
          {...settings}
          className={`container py-30 text-white ${styles.carousel}`}
        >
          {tiles?.map((item: any, index: number) => (
            <div className={`${styles.tiles} py-22 px-20`} key={index}>
              <h5 className="font-bolder-h4">{item?.title}</h5>
              <p>{parser(item?.description)}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default HeroWithTiles;
