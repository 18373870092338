import { Row, Col, Divider } from "antd";
import React from "react";
import Image from "next/image";
import classNames from "classnames";
import Style from "./style.module.scss";
import Button from "@components/shared/Button";
import { AboutProps } from "./types";
import CustomCarousel from "../CustomCarousel";

const AboutUs = ({
  headerImg,
  headerTitle,
  bannerBackgroundImg,
  bannerText,
  selfDefinitionImg,
  selfDefinitionTitle,
  selfDefinitiondescription,
  selfDefinitionbackgroundColor,
  missionTitle,
  missionText,
  missionItems,
  valuesSectionTitle,
  valuesSectionDescription,
  valueSectionArray,
  feedbacksCarouselTitle,
  teamImg,
  teamTitle,
  teamMessage,
  teambackgroundColor,
  buttonText,
  buttonLink
}: AboutProps) => {
  const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${bannerBackgroundImg?.url})`
  };
  return (
    <div className="container pt-10 pb-40 bg-white">
      <div className={Style.headerContainer}>
        <div className={Style.headerImg}>
          <Image
            unoptimized
            src={headerImg?.url}
            alt={headerImg?.altText}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div className="font-bold-lg  lh-lg text-oxfordBlue">{headerTitle}</div>
      </div>
      <div className={classNames("mt-40", Style.bannerImg)} style={bannerStyle}>
        <div
          className={classNames(
            Style.bannerTextWrapper,
            "font-black-xxxl lh-md text-oxfordBlue"
          )}
          dangerouslySetInnerHTML={{
            __html: bannerText
          }}
        />
      </div>
      <Row className="mt-40" style={{ alignItems: "center" }}>
        <Col span={24} md={10}>
          <div
            className={classNames(Style.selfDefinitionِWrapper)}
            style={{ backgroundColor: `${selfDefinitionbackgroundColor}` }}
          >
            <Image
              unoptimized
              src={selfDefinitionImg?.url}
              alt={selfDefinitionImg?.altText}
              layout="fill"
              objectFit="cover"
            />
          </div>
        </Col>
        <Col span={24} md={14} className="bg-white">
          <div className={Style.textImgWrapper}>
            <div
              className={classNames(
                "font-bold-lg mb-10 text-oxfordBlue",
                Style.title
              )}
            >
              {selfDefinitionTitle}
            </div>
            <div
              className={classNames(
                "font-regular-h6 lh-sm px-30 text-oxfordBlue",
                Style.descriptionWrapper
              )}
              dangerouslySetInnerHTML={{
                __html: selfDefinitiondescription
              }}
            />
          </div>
        </Col>
      </Row>
      <div className={classNames("mt-40", Style.mission)}>
        <div
          className={classNames(
            "font-bold-lg lh-lg mb-10 text-oxfordBlue",
            Style.missionTitle
          )}
        >
          {missionTitle}
        </div>
        <div
          className={classNames(
            "font-regular-h6 px-40 text-oxfordBlue",
            Style.missionText
          )}
          dangerouslySetInnerHTML={{
            __html: missionText
          }}
        />
        <div className={classNames("mt-20 mb-20", Style.roundeImgWrapper)}>
          {missionItems &&
            missionItems?.map((item, index) => (
              <div
                className={classNames(Style.roundeItems, "p-14")}
                key={index}
              >
                <div
                  className={Style.roundImage}
                  style={{ backgroundColor: `${item?.backgroundColor}` }}
                >
                  <Image
                    unoptimized
                    src={item?.missionImage?.url || ""}
                    alt={item?.missionImage?.altText}
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
                <div
                  className={classNames(
                    Style.imgDescription,
                    "mt-10 font-bold-h4 lh-1"
                  )}
                >
                  {item?.title}
                </div>
              </div>
            ))}
        </div>
        <Divider type="horizontal" className={Style.divider} />
      </div>
      <Row className={classNames("mt-40", Style.valuesContainer)}>
        <Col span={24} md={12}>
          <div className={classNames(Style.valuesSectionWrapper, "px-15")}>
            <div
              className={classNames(
                Style.valuesSectionTitle,
                "font-bold-lg lh-lg mb-10 text-oxfordBlue"
              )}
            >
              {valuesSectionTitle}
            </div>
            <div
              className={classNames("font-regular-h6 lh-md text-oxfordBlue")}
              dangerouslySetInnerHTML={{
                __html: valuesSectionDescription
              }}
            />
          </div>
        </Col>
        <Col span={24} md={12}>
          <Row justify="space-around" gutter={[16, 16]}>
            {valueSectionArray &&
              valueSectionArray?.map((item, index) => (
                <Col span={24} sm={12} key={index}>
                  <div
                    className={Style.valuesCard}
                    style={{
                      backgroundImage: `url(${item?.Image?.url})`,
                      backgroundColor: `${item?.backgroundColor}`
                    }}
                  >
                    <div
                      className={classNames(
                        Style.valuesCardText,
                        "font-bold-h4 lh-lg"
                      )}
                      style={{ color: `${item?.textColor}` }}
                    >
                      {item?.title}
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <div className="mb-20 mt-40">
        <CustomCarousel
          type="Feedbacks"
          title={feedbacksCarouselTitle}
          viewAll={true}
          btnUrl="/testimonials"
          backgroundColor="#F1EAE5"
        />
      </div>
      <div
        className={classNames(
          "mt-40 py-20",
          Style.recomendationSectionContainer
        )}
      >
        <div
          className={Style.teamimgWrapper}
          style={{ backgroundColor: `${teambackgroundColor}` }}
        >
          <Image
            unoptimized
            src={teamImg?.url}
            alt={teamImg?.altText}
            layout="fill"
            objectFit="contain"
          />
        </div>
        <div
          className={classNames(
            Style.teamimgHeader,
            "font-bold-lg lh-lg mt-4 mb-8 text-oxfordBlue"
          )}
        >
          {teamTitle}
        </div>
        <div
          className={classNames(
            "font-reguler-h6 text-oxfordBlue",
            Style.teamText
          )}
          dangerouslySetInnerHTML={{
            __html: teamMessage
          }}
        />
        <Button
          theme="primary"
          href={buttonLink}
          className={classNames("font-semibold-base mt-10", Style.bttn)}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
export default AboutUs;
