import React, { useEffect } from "react";
import Link from "next/link";
import classNames from "classnames";
import Style from "./styles.module.scss";
import Image from "next/image";
import axios from "axios";
import { getText } from "@src/libs/resources";
import { PostsProps, PressProps } from "./types";
const InPress = ({ title, subTitle, link }: PressProps) => {
  const [pressPosts, setPressPosts] = React.useState<Array<PostsProps>>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get("/api/press/common-press");
        setPressPosts(data);
      } catch (e) {}
    };
    fetchData().catch(console.error);
  }, []);
  return (
    <div className="container py-30 bg-white">
      <div className="font-bold-h4 mb-10">{title}</div>
      <div className={Style.cardsWrapper}>
        {pressPosts &&
          pressPosts?.map((item) => (
              <div className={classNames(Style.cardContainer)} key={item?.id}>
                <div className={classNames("py-3", Style.cardHeader)}>
                  <div className={Style.imgWrapper}>
                    <Image
                      unoptimized
                      src={item?.image}
                      alt="pressPost-logo"
                      layout="fill"
                      objectFit="contain"
                    />
                  </div>
                  <div
                    className={classNames("font-regular-sm", Style.dateWrapper)}
                  >
                    {item?.date}
                  </div>
                </div>
                <div className="font-bold-base mt-18">{item?.title}</div>
                <div
                  className={classNames(
                    "font-regular-sm mt-12",
                    Style.description
                  )}
                >
                  {item?.description}
                </div>
                <div className={classNames("mt-16", Style.footer)}>
                  <Link href={item?.link}>
                    <a className="text-primary">
                      {getText()?.InPress?.readMoreDetails}
                    </a>
                  </Link>
                </div>
              </div>
          ))}
    </div>
    </div>
  );
};
export default InPress;
