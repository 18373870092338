import React, { useEffect } from "react";
import { Row, Col } from "antd";
import Style from "./style.module.scss";
import classNames from "classnames";
import { ContactUsProps } from "./types";
import Script from "next/script";
const ContactUs = ({ title, description }: ContactUsProps) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (
        !window?.hbspt ||
        !process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID ||
        !process.env.NEXT_PUBLIC_CONTACT_US_FORM_ID
      )
        return;
      window?.hbspt?.forms.create({
        region: "na1",
        portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
        formId: process.env.NEXT_PUBLIC_CONTACT_US_FORM_ID,
        target: "#contact",
      });
      clearInterval(timer);
    }, 1000);
  }, []);
  return (
    <div className="container bg-white">
      <Row>
        <Col span={24} xs={24} sm={24} md={20} lg={16} xl={14}>
          {title && (
            <h1
              className={classNames(
                "font-bolder-xl lh-md mb-20 mt-40",
                Style.title
              )}
            >
              {title}
            </h1>
          )}
          {description && (
            <div
              className={classNames(
                Style.description,
                "font-regular-base  lh-sm ms-10"
              )}
              dangerouslySetInnerHTML={{
                __html: description || "",
              }}
            />
          )}
          <div id="contact" className={classNames("mb-30")}>
            <Script
              type="text/javascript"
              src="//js.hsforms.net/forms/embed/v2.js"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
