import styles from "./styles.module.scss";
import { Col, Layout, Menu, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import CustomButton from "@components/shared/Button";
import Accordion from "@components/shared/Accordion";
import Icon from "@components/shared/Icon";
import SearchInput from "@components/shared/Fields";
import SearchIcon from "@assets/icons/search.svg";
import { AppContext } from "~/src/libs/context";
import { useRouter } from "next/router";
import { BuyerVendorProps, faqsProps } from "./types";
import { getText } from "@src/libs/resources";
const { Content, Sider } = Layout;
const HelpCenterPage = ({
  setIsHelpCenterPage,
  vendor,
  buyer,
  category
}: any) => {
  const router = useRouter();
  const initValues = router.asPath?.split("?")?.[1]?.split("-");
  const { helpCenterQuery, setHelpCenterQuery } = useContext<any>(AppContext);
  const [buyerMenu, setBuyerMenu] = useState<BuyerVendorProps[]>();
  const [vendorMenu, setVendorMenu] = useState<BuyerVendorProps[]>();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(helpCenterQuery);
  const [currentCategory, setCurrentCategory] = useState(
    category
      ? category
      : initValues?.[initValues?.length - 1]
      ? initValues?.[initValues?.length - 1]
      : ""
  );
  const [content, setContent] = useState<string>();
  const [accordionItems, setAccordionItems] = useState<faqsProps[]>();
  const [searchText, setSearchText] = useState("")

  const handelSearchTextChange = (e:Event) =>{
    const target = e.target as HTMLInputElement;
    setSearchText(target?.value)
  }
  const filteredFQA = (faq: faqsProps[]) => {
  let filteredItems: faqsProps[];
  filteredItems = faq?.filter((item : faqsProps) => item?.header?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()))
  return filteredItems
 }
  useEffect(() => {
    const buyerData: any = [];
    const vendorData: any = [];
    buyer?.map((item: any) =>
      buyerData?.push({
        label: item?.name,
        key: `${item?.link?.slice(1)}-buyer`,
        faqs: item?.faqs
      })
    );
    vendor?.map((item: any) =>
      vendorData?.push({
        label: item?.name,
        key: `${item?.link?.slice(1)}-vendor`,
        faqs: item?.faqs
      })
    );
    const currentVal = router?.asPath?.split("?")[1];
    if (currentVal?.includes("buyer")) {
      const buyerArr = buyerData?.find((item: any) => item?.key === currentVal);
      setAccordionItems(filteredFQA(buyerArr?.faqs));
      setContent(buyerArr?.label);
    } else {
      const vendorArr = vendorData?.find(
        (item: any) => item?.key === currentVal
      );
      setAccordionItems(filteredFQA(vendorArr?.faqs));
      setContent(vendorArr?.label);
    }
    setBuyerMenu(buyerData);
    setVendorMenu(vendorData);
  }, [searchText]);

  useEffect(() => {
    const currentVal = router?.asPath?.split("?")[1];
    if (currentVal?.includes("buyer") && buyerMenu) {
      const buyerArr = buyerMenu?.find((item: any) => item?.key === currentVal);
      setAccordionItems(buyerArr?.faqs);
      setContent(buyerArr?.label);
    } else if (currentVal?.includes("vendor") && vendorMenu) {
      const vendorArr = vendorMenu?.find(
        (item: any) => item?.key === currentVal
      );
      setAccordionItems(vendorArr?.faqs);
      setContent(vendorArr?.label);
    }
  }, [router]);

  const closeView = () => {
    setIsHelpCenterPage(false);
    router.push("/help-center");
  };
  useEffect(()=>{
    const userType = router?.asPath?.split('?')?.[1]
    const isBuyer = userType?.includes("buyer")
    const isVendor = userType?.includes("vendor")
    isBuyer?onBuyerClick({key:userType}):isVendor?onVendorClick({key:userType}):null
  },[router?.asPath?.split('?')?.[1]])

  const onBuyerClick = (info: any) => {
    const arr = buyerMenu?.find((item) => item?.key === info?.key);
    setAccordionItems(arr?.faqs);
    setContent(arr?.label);
    setCurrent(info?.key);
    setHelpCenterQuery(info.key);
    setCurrentCategory("Buyer");
    setOpen(false);
    router.push(`/help-center?${info.key}`);
  };

  const onVendorClick = (info: any) => {
    const arr = vendorMenu?.find((item) => item?.key === info?.key);
    setAccordionItems(arr?.faqs);
    setContent(arr?.label);
    setOpen(false);
    setCurrentCategory("Vendor");
    setHelpCenterQuery(info.key);
    setCurrent(info.key);
    router.push(`/help-center?${info.key}`);
  };
  const AccordionDetails = ({ content }: any) => {
    return (
      <div className={`font-medium-base pt-6 ${styles.AccordionContainer}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </div>
    );
  };
  return (
    <div className={`${styles.helpCenter__wrapper}`}>
      <Layout
        className={`${styles.helpCenter__container} container bg-transparent text-oxfordBlue`}
      >
        <Sider className={`${styles.helpCenter__sidebar} py-34`}>
          <div>
            <h4 className="font-bold-h4">
              {getText()?.HelpCenter?.buyerTitle}
            </h4>
            <Menu
              className={`${styles.helpCenter__menu} bg-transparent font-medium-base`}
              items={buyerMenu}
              onClick={onBuyerClick}
              selectedKeys={[current]}
            />
          </div>
          <div>
            <h4 className="font-bold-h4 pt-18">
              {getText()?.HelpCenter?.vendorTitle}
            </h4>
            <Menu
              className={`${styles.helpCenter__menu} bg-transparent font-medium-base`}
              items={vendorMenu}
              onClick={onVendorClick}
              selectedKeys={[current]}
            />
          </div>
        </Sider>
        <Content
          className={`${styles.helpCenter__content} bg-white ps-8 py-30 text-oxfordBlue`}
        >
          <div className={`${styles.mobileBtn}`}>
            <button
              onClick={() => setOpen(true)}
              className={`${styles.menuBtn} bg-oxfordBlue`}
            >
              {open ? (
                <Icon
                  name="cross"
                  width={18}
                  height={18}
                  color="#ffffff"
                  dir={"bottom"}
                />
              ) : (
                <Icon name="menu" width={18} height={18} color="#ffffff" />
              )}
            </button>
          </div>
          {open && (
            <div className={`${styles.mobileSidebar}  pt-20 bg-white`}>
              <div className={`${styles.menuWrapper}`}>
                <div className={`${styles.header} px-8`}>
                  <h4 className="font-bold-h4">
                    {getText()?.HelpCenter?.buyerTitle}
                  </h4>
                  <button
                    onClick={() => setOpen(false)}
                    className={`${styles.menuBtn} bg-oxfordBlue`}
                  >
                    <Icon name="cross" width={18} height={18} color="#ffffff" />
                  </button>
                </div>
                <Menu
                  className={`${styles.helpCenter__menu} bg-transparent font-medium-base`}
                  items={buyerMenu}
                  onClick={onBuyerClick}
                  selectedKeys={[current]}
                />
              </div>
              <div className={`${styles.menuWrapper}`}>
                <h4 className="font-bold-h4 pt-8 ps-8">
                  {getText()?.HelpCenter?.vendorTitle}
                </h4>
                <Menu
                  className={`${styles.helpCenter__menu} bg-transparent font-medium-base`}
                  items={vendorMenu}
                  onClick={onVendorClick}
                  selectedKeys={[current]}
                />
              </div>
            </div>
          )}

          <CustomButton
            className={`${styles.helpCenterBtn} ps-0 font-bold-h6 bg-transparent text-oxfordBlue mb-12`}
            onClick={closeView}
          >
            <Icon name="arrow" dir="bottom" />
            {getText()?.HelpCenter?.backToHelpCenter}
          </CustomButton>
          <Row justify="space-between" gutter={[16, 16]}>
            <Col
              className="font-bold-lg"
              style={{ textTransform: "capitalize" }}
            >
              <span>
                {currentCategory} - {content}
              </span>
            </Col>
            <Col>
              <SearchInput
                type="search"
                className={styles.searchField}
                onChange={(e: any) => handelSearchTextChange(e)}
                onIconClick={(e: any) => handelSearchTextChange(e)}
                onPressEnter={(e: any) => handelSearchTextChange(e)}
                placeholder="Search"
                hasIcon={true}
                Icon={SearchIcon}
                allowClear={{ clearIcon: <Icon name="mobileCross" classesName="d-flex py-5"/> }}
                value={searchText}
                prefix={true}
              />
            </Col>
          </Row>
          {accordionItems?.map((item, index) => (
            <Accordion
              className={`${styles.accordionItem} py-8`}
              key={index}
              header={item?.header}
              closedIcon={
                <Icon
                  name={"down"}
                  width={24}
                  height={24}
                  dir={""}
                  color="#02254a"
                />
              }
              openedIcon={
                <Icon
                  name={"down"}
                  width={24}
                  height={24}
                  dir={"top"}
                  color="#02254a"
                />
              }
              content={<AccordionDetails content={item?.content} />}
            />
          ))}
        </Content>
      </Layout>
    </div>
  );
};
export default HelpCenterPage;
