import { Col, Image, Row } from "antd";
import { FeaturedBannerProps } from "./types";
import parser from "html-react-parser";
import styles from './style.module.scss'
const FeaturedMoveBanner = ({
  bannerTitle,
  bannerDescription,
  cards,
  cardWithBorder,
}: FeaturedBannerProps) => {
  return (
    <div className="container py-30">
      {bannerTitle && <h1 className={`font-bolder-lg pb-6 text-darkGray ${styles.title}`}>{parser(bannerTitle || "")}</h1>}
      {parser(bannerDescription || "") && (
        <div className={`font-medium-h4 text-darkGray ${styles.subtitle}`}>{parser(bannerDescription || "")}</div>
      )}
      <Row className="pt-16 text-darkGray" justify="space-between">
        {cards?.map((item: any, index: number) => (
          <Col key={index} span={24} md={7} className="mb-20">
            <Image  src={item?.image?.url} alt={item?.image?.altText} preview={false} />
            <h2 className={`font-bolder-h4 pt-4 pb-8 ${styles.itemTitle}`}>{item?.title}</h2>
            <p className={`font-regular-h6 ${styles.description}`}>{parser(item?.description || "")}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FeaturedMoveBanner;
