import { useContext, useEffect, useState } from "react";
import { AppContext } from "@src/libs/context";
import { verticalSpaceProps } from "./types";

const VerticalSpace = ({
  verticalSpaceValue,
  verticalSpaceUnit,
  AllowedUsers,
}: verticalSpaceProps) => {
  
  const { guestUser } = useContext<any>(AppContext);
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);

  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);
  
  return (
    <>
    {isGuestUser && (AllowedUsers === "Guest" || AllowedUsers === "Both") &&
    <div
      style={{
        height: `${verticalSpaceValue}${verticalSpaceUnit}`,
      }}
    />
    }
    {!isGuestUser &&
        (AllowedUsers === "Registered" || AllowedUsers == "Both") &&
        <div
        style={{
          height: `${verticalSpaceValue}${verticalSpaceUnit}`,
        }}
      />
    }
    </>
  )
};

export default VerticalSpace;
