import React, { useContext, useEffect, useState } from "react";
import { FormProps } from "./types";
import { Col, Form, Row, notification } from "antd";
import CustomButton from "../Button";
import Field from "../Fields";
import styles from "./styles.module.scss";
import { getText } from "~/src/libs/resources";
import RequestQuoteModal from "./RequestQuoteModal";
import { RuleObject } from "antd/es/form";
import Script from "next/script";
import { AppContext } from "~/src/libs/context";
import SearchLocationInput from "../AutocompleteAddress";
import useAddressValidator from "./useAddressValidator";

const RequestQuote = ({
  heading,
  backgroundColor,
  buttonLabel,
}: FormProps): JSX.Element => {
  const [form] = Form.useForm();
  const {
    onBlurHandler
  } = useAddressValidator();
  const { googlePlacesScript, setGooglePlacesScript } =
    useContext<any>(AppContext);
  const [isAddressFieldValid, setIsAddressFieldValid] = useState<boolean>(true);
  const [isAddressFieldValidDestination, setIsAddressFieldValidDestination] =
    useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const hasUndefinedProperty = (obj:any) => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && typeof obj[key] === 'undefined') {
        return true;
      }
    }
    return false;
  };
  useEffect(()=>{
       if(!isAddressFieldValid || !isAddressFieldValidDestination){
        setDisabledSave(true)
       }
  },[isAddressFieldValid, isAddressFieldValidDestination])
  const handleFormChange = () => {
    const hasErrors = form?.getFieldsError()?.some(({ errors }) => errors.length);
    const hasUndefinedValue = hasUndefinedProperty(form?.getFieldsValue());
    setDisabledSave(hasErrors || hasUndefinedValue);
  };
  const handleSelectAddress = (value: any, inputLabel: string) => {
    const items: any = document.getElementsByClassName("pac-container");
    if (inputLabel === "destination") {
      for (let i = 0; i < items.length; i++) {
        document
          .getElementById("pac-container-parent-destination")
          ?.append(items[i]);
      }
      form.setFieldValue("Destination", value);
      form.validateFields(["Destination"]);
    } else {
      for (let i = 0; i < items.length; i++) {
        document.getElementById("pac-container-parent")?.append(items[i]);
      }
      form.setFieldValue("Origin", value);
      form.validateFields(["Origin"]);
    }
  };
  const handleSelectLocation = (value: any, inputLabel: string) => {
    if (inputLabel === "origin") {
      form.setFieldValue("Origin", value?.formatted_address);
      form.validateFields(["Origin"]);
    } else {
      form.setFieldValue("Destination", value?.formatted_address);
      form.validateFields(["Destination"]);
    }
  };
  const onFocus = (inputLabel: string) => {
    if (googlePlacesScript) {
      const items: any = document.getElementsByClassName("pac-container");
      if (inputLabel === "origin") {
        for (let i = 0; i < items.length; i++) {
          document.getElementById("pac-container-parent")?.append(items[i]);
        }
      } else {
        for (let i = 0; i < items.length; i++) {
          document
            .getElementById("pac-container-parent-destination")
            ?.append(items[i]);
        }
      }
    }
  };

  const addressFieldValidation = (
    _: RuleObject,
    value: string,
    fieldName: string
  ) => {
    if ((fieldName === "destination" || fieldName === "origin") && !value) {
      fieldName === "origin"
        ? setIsAddressFieldValid(false)
        : setIsAddressFieldValidDestination(false);
      return Promise.reject("This field should not be empty.");
    } else if (
      fieldName === "destination" ||
      (fieldName === "origin" && value)
    ) {
      fieldName === "origin"
        ? setIsAddressFieldValid(true)
        : setIsAddressFieldValidDestination(true);
      return Promise.resolve();
    }
  };
  const onFinish = async (values: any) => {
    setSaveLoader(true);
    let response;
    try {
      setFormValues(values);
      setIsModalOpen(true);
      setSaveLoader(false);
    } catch (error: any) {
      setSaveLoader(false);
      notification.error({
        message: `${
          error["response"]?.data?.detail ||
          getText()?.General?.Messages?.SomethingWrongTryAgain
        }`,
        duration: 4,
      });
    }
  };
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\D/g, "");
    e.target.value = sanitizedValue;
  };
  const handleKeyPress = (e: any) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const preventNegativeValue = (e: any) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  return (
    <div
      className={`py-30 ${styles.requestQuoteWrapper}`}
      style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}
    >
      <div className="container">
        {heading && (
          <h3
            className={`font-bolder-lg text-platinum mb-20 ${styles.heading}`}
          >
            {heading}
          </h3>
        )}
        <Form
          className={styles.requestQuoteForm}
          form={form}
          onFinish={onFinish}
          onFieldsChange={handleFormChange}
        >
          <Row justify="space-between" className={`${styles.inputsWrapper}`}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} className={styles.origin}>
              <Form.Item
                name="Origin"
                rules={[
                  () => ({
                    validator: (_, value) =>
                      addressFieldValidation(_, value, "origin"),
                  }),
                ]}
              >
                <>
                {!googlePlacesScript && (
                  <Script
                    src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLEACES_KEY}&libraries=places`}
                    onLoad={() => {
                      setGooglePlacesScript(true);
                    }}
                  />
                )}
                {googlePlacesScript && (
                  <>
                    <SearchLocationInput
                      className={"border-radius-zero addressStyles"}
                      disabled={false}
                      placeHolder="Origin"
                      extraAction={(value: any) =>
                        handleSelectLocation(value, "origin")
                      }
                      name="Origin"
                      types={["address"]}
                      isBilling={false}
                      onChange={(value: any) =>
                        handleSelectAddress(value, "origin")
                      }
                      validInput={isAddressFieldValid}
                      onFocus={() => onFocus("origin")}
                      smallFontSize={true}
                      setFieldValueCallback={(value: string) =>
                        onBlurHandler(value, "origin", form, setIsAddressFieldValidDestination, setIsAddressFieldValid)
                      }
                    />
                    <div
                      id="pac-container-parent"
                      className={styles["pac-container-parent"]}
                    ></div>
                  </>
                )}
                </>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              className={styles.destination}
            >
              <Form.Item
                name="Destination"
                rules={[
                  () => ({
                    validator: (_, value) =>
                      addressFieldValidation(_, value, "destination"),
                  }),
                ]}
              >
                <>
                {!googlePlacesScript && (
                  <Script
                    src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLEACES_KEY}&libraries=places`}
                    onLoad={() => {
                      setGooglePlacesScript(true);
                    }}
                  />
                )}
                {googlePlacesScript && (
                  <>
                    <SearchLocationInput
                      className={"border-radius-zero addressStyles"}
                      disabled={false}
                      placeHolder="Destination"
                      extraAction={(value: any) =>
                        handleSelectLocation(value, "destination")
                      }
                      name="Destination"
                      types={["address"]}
                      isBilling={false}
                      onChange={(value: any) =>
                        handleSelectAddress(value, "destination")
                      }
                      validInput={isAddressFieldValidDestination}
                      onFocus={() => onFocus("destination")}
                      smallFontSize={true}
                      setFieldValueCallback={(value: string) =>
                        onBlurHandler(value, "destination", form, setIsAddressFieldValidDestination, setIsAddressFieldValid)
                      }
                    />
                    <div
                      id="pac-container-parent-destination"
                      className={styles["pac-container-parent-destination"]}
                    ></div>
                  </>
                )}
                </>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={24} lg={9}>
              <Row wrap={false}>
                <Form.Item
                  name="Length"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    min={0}
                    type="number"
                    placeholder="Length (in)"
                    label="Length (in)"
                    onKeyPress={preventNegativeValue}
                  />
                </Form.Item>
                <Form.Item
                  name="Width"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    onKeyPress={preventNegativeValue}
                    type="number"
                    placeholder="Width (in)"
                    label="Width (in)"
                  />
                </Form.Item>
                <Form.Item
                  name="Height"
                  rules={[
                    {
                      required: true,
                      message: "This field should not be empty",
                    },
                  ]}
                >
                  <Field
                    onKeyPress={preventNegativeValue}
                    type="number"
                    placeholder="Height (in)"
                    label="Height (in)"
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={24} lg={9}>
              <Row
                wrap={false}
                justify="space-between"
                className={styles.inputGroup}
              >
                <Col span={11}>
                  <Form.Item
                    name="Weight"
                    rules={[
                      {
                        required: true,
                        message: "This field should not be empty",
                      },
                    ]}
                  >
                    <Field
                      onKeyPress={preventNegativeValue}
                      type="number"
                      placeholder="Weight (lbs)"
                      label="Weight (lbs)"
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="No_of_Pallets"
                    rules={[
                      {
                        required: true,
                        message: "This field should not be empty",
                      },
                    ]}
                  >
                    <Field
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      type="number"
                      placeholder="No. of Pallets"
                      label="No. of Pallets"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={5}>
              <div className={`${styles.buttonWrapper} ps-10`}>
                <CustomButton
                  theme="primary"
                  onClick={() => {
                    form?.submit();
                  }}
                  className="py-7 h-auto w-100 font-bold-base"
                  disabled={disabledSave}
                  loading={saveLoader}
                >
                  {buttonLabel}
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <RequestQuoteModal
        formData={form}
        formValues={formValues}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
    </div>
  );
};

export default RequestQuote;
