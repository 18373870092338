import React, { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import styles from "./styles.module.scss";
import { SimplySellMoreProps } from "./types";
import CustomButtons from "@components/shared/Button";
import parser from "html-react-parser";
import { AppContext } from "@src/libs/context";
import { useWindowResize } from "@src/utilities/hooks/useWindowResize";

const SimplySellMore = ({
  image,
  mobileImage,
  heading,
  subHeading,
  linkLabel,
  linkLabelMobile,
  linkUrl,
  AllowedUsers,
  promotionID
}: SimplySellMoreProps): JSX.Element => {
  const router = useRouter();
  const isMobile = useWindowResize() <= 576;
  const isTablet = useWindowResize() <= 992;
  const isSmallScreen = useWindowResize() < 1200;
  const { guestUser } = useContext<any>(AppContext);
  const [isGuestUser, setIsGuestUser] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);
  const stringToHTML = function (str: string) {
    const dom = document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };

  const checkIfGuest =
    (isGuestUser && AllowedUsers === "Registered") ||
    (!isGuestUser && AllowedUsers === "Guest");

  return typeof checkIfGuest !== "undefined" && !checkIfGuest ? (
    <div className={styles.heroContainer}>
      <div>
        <img
          className={styles.heroImage}
          style={{
            height: "auto",
            minHeight: isSmallScreen && !isTablet ? "460px" : ""
          }}
          src={isMobile ? mobileImage?.url : image?.url}
          alt={isMobile ? mobileImage?.altText : image?.altText}
        />
        <div className={[styles.heroContentContainer].join(" ")}>
          <div>
            <h2
              className={[
                styles.heading,
                isTablet
                  ? "font-bolder-h2"
                  : isSmallScreen
                  ? "font-bolder-xl"
                  : "font-bolder-xxl"
              ].join(" ")}
            >
              {parser(heading || "")}
            </h2>
            {!isTablet && (
              <h3 className="mb-20 text-white font-regular-h5">
                {parser(subHeading || "")}
              </h3>
            )}
            <CustomButtons
              className={styles.linkBtn}
              theme="primary-inverse"
              onClick={() => router.push(linkUrl)}
            >
              {isMobile ? linkLabelMobile || linkLabel : linkLabel}
            </CustomButtons>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SimplySellMore;
