import { Row,Col } from "antd";
import React from "react";
import CustomButton from "@components/shared/Button";
import style from "./style.module.scss";
import Image from "next/image";
import {JoinOurProps} from "./types"
const JoinOurSection = ({title,description,image,buttonText,buttonLink }: JoinOurProps) => {
  return (
    <div className={`container pt-32 pb-40 ${style.mainWrapper}`}>
      <Row className={`${style.sectionWrapper}`} style={{ alignItems: "center" }}>
        <Col span={24} order={2}  md={{span: 10, order: 1 }}>
         <div className={`p-30 ${style.leftSectionContainer}`}>
            <div className={`font-bolder-lg mb-15 ${style.title}`}>{title}</div>
             <div className={`font-regular-h6 mb-15 lh-lg ${style.description}`}
              dangerouslySetInnerHTML={{
                __html: description
              }}
             />
             {buttonText && <CustomButton
              className={`font-bold-base ${style.joinBtn}`}
              theme="primary"
              href={buttonLink}
              >
                {buttonText}
              </CustomButton>}
         </div>
        </Col>
        <Col span={24}  order={1}  md={{span: 14, order: 2 }}>
         <div className={style.teamImage}>
          <Image
            unoptimized
            src={image?.url}
            alt={image?.altText}
            layout="fill"
            objectFit="contain"
          />
        </div>
        </Col>
      </Row>
    </div>
  );
};

export default JoinOurSection;
