import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Style from "./style.module.scss";
import Image from "next/image";
import Icon from "@components/shared/Icon";
import { Row, Col, Dropdown, Space } from "antd";
import { testimonialsProps, testimonialsArrProps } from "./types";
import axios from "axios";
import Button from "@components/shared/Button";
import { Pagination } from "antd";
import { getText } from "@src/libs/resources";
import { filterItem } from "@types";

const Testimonials = ({
  title,
  bannerImg,
  cardBackground,
  titleColor,
}: testimonialsProps) => {
  const [testimonialsData, settesTimonialsData] = React.useState<
    Array<testimonialsArrProps>
  >([]);
  const [displayedCards, setdisplayedCards] = React.useState<
    Array<testimonialsArrProps>
  >([]);
  const TestimonialsCardsRef: any = useRef();
  const [clickedButtons, setClickedButtons] = useState<filterItem[]>([]);
  const [itemValue, setItemsVal] = useState(3);
  const [openMenu, setOpenMenu] = useState(false);
  const [pageSize, setpageSize] = useState(3);
  const [totalPage, settotalPage] = useState<number>(0);
  const [minIndex, setminIndex] = useState(0);
  const [maxIndex, setmaxIndex] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${bannerImg?.url})`,
  };
  const itemsPerPage = [
    {
      label: "3",
      key: "3",
    },
    {
      label: "6",
      key: "6",
    },
    {
      label: "9",
      key: "9",
    },
    {
      label: "12",
      key: "12",
    },
  ];
  const showDrawer = () => {
    setOpenMenu(true);
  };
  const handleChange = (e: any) => {
    setItemsVal(e?.key);
    setpageSize(Number(e?.key));
    setPageNum(1);
    setminIndex(0);
    setmaxIndex(pageSize);
    setTimeout(() => {
      window.scrollTo({ top: TestimonialsCardsRef?.current?.offsetTop, left: 0, behavior: "smooth" });
    }, 100);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get("/api/ERP/common/testimonials");
        settesTimonialsData(data);
        setdisplayedCards(data);
      } catch (e) {}
    };
    fetchData().catch();
  }, []);
  useEffect(() => {
    settotalPage(testimonialsData?.length / pageSize);
    setmaxIndex(pageSize);
  }, [testimonialsData, pageSize]);

  const handlePageChange = (page: any) => {
    setPageNum(page);
    setminIndex((page - 1) * pageSize);
    setmaxIndex(page * pageSize);
    setTimeout(() => {
      window.scrollTo({ top: TestimonialsCardsRef?.current?.offsetTop, left: 0, behavior: "smooth" });
    }, 100);
  };
  useEffect(() => {
    const arr = testimonialsData?.filter((value) =>
      clickedButtons?.find(
        (i) => i?.header === value?.type && i?.item === value?.businessType
      )
    );
    clickedButtons?.length
      ? setdisplayedCards(arr)
      : setdisplayedCards(testimonialsData);
  }, [clickedButtons]);

  return (
    <div className="container bg-white pt-40">
      <div
        className={classNames(
          "font-regular-h4 text-oxfordBlue",
          Style.titleContainer
        )}
        style={bannerStyle}
      >
        <div
          className={classNames(Style.bannerTextWrapper, "font-bold-xxl lh-md")}
          style={{ color: titleColor }}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </div>
      {/* add filter tags for testimonials */}
      {/* <div className={classNames(Style.filterWrapper, "mb-10 mt-30 py-10")}>
        <Button
          theme="primary"
          className={classNames(
            Style.filterBttn,
            "text-oxfordBlue bg-white font-bold-base"
          )}
          icon={<Icon name="filters" width={18} height={18} />}
          onClick={showDrawer}
        >
          Filters
        </Button>
        <DrawerMenu
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          setClickedButtons={setClickedButtons}
        />
      </div> */}
      <Row gutter={[16, 16]} className="pt-10" ref={TestimonialsCardsRef}>
        {displayedCards &&
          displayedCards?.map(
            (item, index) =>
              index >= minIndex &&
              index < maxIndex && (
                <Col
                  span={24}
                  md={12}
                  lg={12}
                  xl={8}
                  key={index}
                  style={{ minHeight: "395px" }}
                >
                  <div
                    className={classNames(Style.cardContainer, "p-14")}
                    style={{ backgroundColor: `${cardBackground}` }}
                    key={item?.id}
                  >
                    <div
                      className={classNames(
                        "font-bold-h5 lh-md mb-10 text-oxfordBlue"
                      )}
                    >
                      {item?.title}
                    </div>
                    <div
                      className={classNames(
                        "font-regular-sm lh-sm mb-10 text-oxfordBlue"
                      )}
                    >
                      {item?.review}
                    </div>
                    <div className={classNames(Style.footerCard)}>
                      <div className={classNames(Style.imgWrapper)}>
                        <Image
                          src={item?.author_image}
                          alt="author image"
                          layout="fill"
                          objectFit="contain"
                        />
                      </div>
                      <div className={classNames("ms-10")}>
                        <div
                          className={classNames(
                            "font-medium-h5 text-oxfordBlue"
                          )}
                        >
                          {item?.author_name}
                        </div>
                        <div
                          className={classNames(
                            "font-regular-h5 text-oxfordBlue",
                            Style.authPosition
                          )}
                        >
                          {item?.author_position}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )
          )}
      </Row>
      <div className={classNames("mt-30 pt-20", Style.footer)}>
        <div
          className={classNames(Style.pageNoWrapper, "font-medium-h5 py-15")}
        >
          {getText()?.Testimonials?.page} {pageNum}
          <span className="ps-2">{getText()?.Testimonials?.of}</span> {Math.ceil(totalPage)}
        </div>
        <div className={classNames(Style.paginiation, "ms-10")}>
          <Pagination
            pageSize={pageSize}
            current={pageNum}
            total={testimonialsData?.length}
            onChange={handlePageChange}
            hideOnSinglePage={true}
          />
        </div>
        <div className={classNames(Style.pageNoWrapper)}>
          <div className={classNames(Style.itemsPerPageContainer)}>
            <div className={classNames("font-medium-h5 me-4")}>
              {getText()?.Testimonials?.itemsPerPage}
            </div>
            <Dropdown menu={{ items: itemsPerPage, onClick: handleChange }}>
              <Button theme={"secondary"} className={classNames(Style.select)}>
                <Space>
                  <div className={Style.numWrapper}>{itemValue}</div>
                  <Icon name="down" width={14} height={14} />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonials;
