import React, { useState } from "react";
import { HeroProps } from "./types";
import { Row, Col, Image } from "antd";
import CustomButton from "../Button";
import styles from "./styles.module.scss";
import parser from "html-react-parser";
import ContactUsModal from "../HaggleModal/HaggleModal";
const HalfHeroBanner = ({
  image,
  heading,
  buttonLabel,
  backgroundColor,
}: HeroProps): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Row>
      <Col span={24} md={12} className={styles.imageWrapper}>
        <Image className={styles.image} preview={false} src={image?.url} width="100%" />
      </Col>
      <Col
        span={24}
        md={12}
        className={`${styles.bannerTitle} container`}
        style={{ background: backgroundColor ? backgroundColor : "none" }}
      >
        {heading && (
          <div className="my-12">
            <h3 className="font-bold-h3 text-darkGray">
              {parser(heading)}
            </h3>
            {buttonLabel && (
              <div>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  className="mt-10 font-bold-base py-6 h-auto"
                  text={buttonLabel}
                  theme="primary"
                />
              </div>
            )}
          </div>
        )}
      </Col>
      <ContactUsModal
        title="Contact Us"
        subTitle="Let us share how Move can unlock your logistics"
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
      />
    </Row>
  );
};

export default HalfHeroBanner;
