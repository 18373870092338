import React, { useContext, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import Icon from "@components/shared/Icon";
import CustomButton from "@components/shared/Button";
import styles from "./styles.module.scss";
import { OrderConfirmationType } from "./types";
import { getText } from "~/src/libs/resources";
import { useWindowResize } from "~/src/utilities/hooks/useWindowResize";
import requireLogin from "~/src/utilities/auth";
import Link from "next/link";
import { AppContext } from "~/src/libs/context";

const OrderConfirmation = ({
  thanksMessage,
  orderDescription,
  contactUsLabel,
  contactUsURL,
  continueShoppingLabel,
  continueShoppingURL
}: OrderConfirmationType) => {
  const router = useRouter();
  const {canAccessOrderPage, setCanAccessOrderPage} = useContext<any>(AppContext)
  const isMobile = useWindowResize() <= 768;
  const orderNumbers = useMemo(
    () => router?.query["orderId"]?.toString().split(",") || [],
    []
  );
  const customerPO = useMemo(() => router?.query["po"] || "", []);
  useEffect(()=>{
      if(canAccessOrderPage){
        setCanAccessOrderPage(false)
      }
      else{
        router.push("/")
      }
  },[])
  return (
    <div className={`${styles.confirmationContainer} container`}>
      <Icon
        name="check"
        color="#076DDC"
        width={isMobile ? 60 : 85}
        height={isMobile ? 60 : 85}
      />
      <h1 className={styles.thanksMessage}>{thanksMessage}</h1>
      {orderNumbers?.length === 1 && (
        <h3 className={styles.orderNumberLabel}>
          {getText()?.OrderConfirmation?.singleOrderNumberLabel}
          <Link href={`/account/myOrders?orderID=${orderNumbers[0]}`}>
            <a>
              <span className={styles.number}>{orderNumbers[0]}</span>
            </a>
          </Link>
        </h3>
      )}
      {orderNumbers?.length > 1 &&
        orderNumbers?.map((number) => (
          <h3 key={number} className={styles.orderNumberLabel}>
            {getText()?.OrderConfirmation?.orderNumberLabel}{" "}
            <Link href={`/account/myOrders?orderID=${number}`}>
              <a>
                <span className={styles.number}>{number}</span>
              </a>
            </Link>
          </h3>
        ))}
      <div className={styles.orderDescription}>
        {getText()?.OrderConfirmation?.receivedYour}
        {customerPO ? `PO#${customerPO} ` : "order "}{" "}
        {getText()?.OrderConfirmation?.haveForwardedVendors}
        {orderDescription}
      </div>
      <div className={styles.experienceBox}></div>
      <div className={styles.actionsContainer}>
        <CustomButton
          onClick={() => router.push(contactUsURL)}
          theme="secondary"
        >
          {contactUsLabel}
        </CustomButton>
        <CustomButton
          theme="primary"
          onClick={() => router.push(continueShoppingURL)}
        >
          {continueShoppingLabel}
        </CustomButton>
      </div>
    </div>
  );
};

export default requireLogin(OrderConfirmation, "/");
