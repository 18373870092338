import React, { useContext, useEffect, useState } from "react";
import Icon from "@components/shared/Icon";
import { getText } from "~/src/libs/resources";
import RequestForm from "../requestForm";
import styles from "./style.module.scss";
import CustomButton from "@components/shared/Button";
import { AppContext } from "~/src/libs/context";
import { RequestProductProps } from "./types";

function RequestProduct({
  AllowedUsers,
  title,
  buttonLabel,
}: RequestProductProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { guestUser } = useContext<any>(AppContext);
  const [isGuestUser, setIsGuestUser] = useState<boolean | undefined>(
    undefined
  );
  const showModal = () => {
    setIsModalOpen(true);
  };

  const checkIfGuest =
    (isGuestUser && AllowedUsers === "Registered") ||
    (!isGuestUser && AllowedUsers === "Guest");
  useEffect(() => {
    setIsGuestUser(guestUser);
  }, [guestUser]);

  return !checkIfGuest ? (
    <div className={`${styles.noResult} py-20`}>
      <div>
        <Icon name="find_product" width={54} height={54} />
      </div>

      <h3 className={styles.title}>{title}</h3>
      <CustomButton
        className={styles.requestItBtn}
        theme="primary"
        onClick={showModal}
      >
        {buttonLabel}
      </CustomButton>
      <RequestForm setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </div>
  ) : null;
}

RequestProduct.defaultProps = {
  title: getText()?.Search?.canNotFindAProduct,
  buttonLabel: getText()?.Search?.requestIt,
};

export default RequestProduct;
